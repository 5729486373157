<template>
  <div class="enbusiness">
    <div class="image">
      <img :src="indexImg" style="width: 100%; height: 100%" />
    </div>
    <div class="layout">
      <div class="listcontent">
        <div class="worldwide">
          <p>WorldwideFootprint</p>
          <div></div>
          <img src="../assets/image/WorldwideFootprint.jpg" />
        </div>
      </div>
    </div>
    <div class="business">
      <div class="businesswrap">
        <div class="worldwide">
          <p>BUSINESS</p>
          <div></div>
        </div>
        <ul>
          <li v-for="(item, index) in businesslist" :key="index" @click="addCss(index)">
            <img :src="item.urlb" v-if="cssOn !== index" />
            <img :src="item.urla" v-if="cssOn == index" />
            <p :class="{ businessActive: cssOn == index }">{{ item.title }}</p>
            <div v-if="cssOn == index"></div>
          </li>
        </ul>
      </div>
    </div>
    <div class="businesswrap">
      <div class="businessitem" style="margin-bottom:30px;">
        <a
          v-if="this.listData[0].url && this.listData[0].url !== '' && this.listData[0].url !== null"
          :href="this.listData[0].url"
        >
          <div class="businessitemL">
            <p>{{ this.listData[0].title }}</p>
            <div>{{ this.listData[0].artDesc }}</div>
            <span>Detail+</span>
          </div>
          <div class="businessitemR">
            <img :src="this.listData[0].thumb1" />
          </div>
        </a>
        <a v-else :href="`/detail/${this.listData[0].entTChannelUuid}/${this.listData[0].entTArticleUuid}`">
          <div class="businessitemL">
            <p>{{ this.listData[0].title }}</p>
            <div>{{ this.listData[0].artDesc }}</div>
            <span>Detail+</span>
          </div>
          <div class="businessitemR">
            <img :src="this.listData[0].thumb1" />
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getChildChannel,
  getChannelInfoNew,
  articles
  // getCookie,
  // getAllArtListByParentUuid
} from '@/api/common'

export default {
  name: 'list',
  data() {
    return {
      cssOn: 0,
      isMenuShow: true,
      isthird: false,
      isClickid: '',
      isClickid2: '',
      slideHdName1: '',
      slideHdName2: '',
      slideHdTitle: '',
      viewSlideHdParent: true,
      slideHdParentName: '',
      slideHdParentUrl: '',
      slideHdName: '',
      hdTitle: '',
      hdName: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '新闻动态'
      },
      slideList: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      listData: [],
      listDataEn: [],
      listId: '',
      indexImg: '',
      noempty: false,
      applicationUuid: '',
      lang: 'zh-CN',
      businesslist: [
        {
          title: 'Construction & Installation',
          urlb: require('../assets/image/item1b.png'),
          urla: require('../assets/image/item1a.png')
        },
        {
          title: 'Road & Municipal',
          urlb: require('../assets/image/item2b.png'),
          urla: require('../assets/image/item2a.png')
        },
        {
          title: 'Industrial Construction',
          urlb: require('../assets/image/item3b.png'),
          urla: require('../assets/image/item3a.png')
        },
        {
          title: 'International Trade',
          urlb: require('../assets/image/item4b.png'),
          urla: require('../assets/image/item4a.png')
        },
        {
          title: 'Labor Export',
          urlb: require('../assets/image/item5b.png'),
          urla: require('../assets/image/item5a.png')
        },
        {
          title: 'Diversified Industries',
          urlb: require('../assets/image/item6b.png'),
          urla: require('../assets/image/item6a.png')
        },
        {
          title: 'Others',
          urlb: require('../assets/image/item7b.png'),
          urla: require('../assets/image/item7a.png')
        }
      ]
    }
  },
  filters: {
    ellipsis: function(value) {
      if (!value) return ''
      if (value.length > 120) {
        return value.slice(0, 120) + '...'
      }
      return value
    },
    ellipsis2: function(value) {
      if (!value) return ''
      if (value.length > 45) {
        return value.slice(0, 45) + '...'
      }
      return value
    }
  },
  mounted() {
    var _this = this
    _this.active = _this.$route.params.id
    _this.listId = _this.$route.params.id
    // let lang = getCookie('lang')
    let lang = localStorage.getItem('lang')
    if (lang == 'en-US') {
      this.applicationUuid = this.webIdEn
    } else {
      this.applicationUuid = this.webId
    }

    _this.getChannelInfoNew()
    _this.articles()
  },
  methods: {
    addCss(index) {
      this.cssOn = index
      this.articlesinfo()
    },
    menuShow() {
      this.isMenuShow = !this.isMenuShow
    },
    // 查询二级栏目
    getChildChannel(id) {
      var _this = this
      let data = {
        applicationUuid: this.applicationUuid,
        parentUuid: id
      }
      getChildChannel(data).then(res => {
        const { code, data } = res
        // 查询所有二级菜单
        if (code === 1 && data) {
          _this.slideList = data
          console.log(_this.slideList)
        }
      })
    },
    getChannelInfoNew() {
      var _this = this
      let data = {
        channelUuid: _this.$route.params.id,
        applicationUuid: this.applicationUuid
      }
      getChannelInfoNew(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.slideHdName = data.name
          if (data.parentUuid == '0') {
            _this.slideHdTitle = data.name
            _this.viewSlideHdParent = false
            if (data.fileName && data.fileName !== '') {
              _this.indexImg = data.fileName
            } else {
              _this.indexImg = require('../assets/image/partyBuilding.jpg')
            }
            return
          }

          _this.getChildChannel(data.parentUuid)

          let params = {
            channelUuid: data.parentUuid,
            applicationUuid: this.applicationUuid
          }
          getChannelInfoNew(params).then(res => {
            const { code, data } = res
            if (code === 1 && data) {
              _this.slideHdParentName = data.name
              _this.slideHdTitle = data.name
              if (data.fileName && data.fileName !== '') {
                _this.indexImg = data.fileName
              } else {
                _this.indexImg = require('../assets/image/partyBuilding.jpg')
              }
            }
          })
        }
      })
    },
    articlesinfo() {
      var _this = this
      let id = ''
      id = _this.slideList[this.cssOn].entTChannelUuid
      let data = {
        entTApplicationUuid: _this.webIdEn,
        channelUuid: id,
        pageNum: _this.pageNum,
        pageSize: _this.pageSize
      }
      articles(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.listData = data.rows
          _this.total = data.totalRows
        } else {
          this.noempty = true
        }
      })
    },
    articles() {
      var _this = this
      let id = ''
      var applicationId = ''
      // 集团新闻
      if (_this.$route.params.id == '01800003') {
        id = '00190001'
        applicationId = _this.webIdGroup
      } else {
        // let data = this.cssOn
        // id = _this.slideList[data].entTChannelUuid
        id = _this.$route.params.id
        applicationId = _this.webId
      }
      _this.$i18n.locale = localStorage.getItem('lang') || 'zh-CN'
      if (_this.$i18n.locale == 'en-US') {
        let data = {
          entTApplicationUuid: _this.webIdEn,
          channelUuid: id,
          pageNum: _this.pageNum,
          pageSize: _this.pageSize
        }
        articles(data).then(res => {
          const { code, data } = res
          if (code === 1 && data) {
            _this.listData = data.rows
            _this.total = data.totalRows
          } else {
            this.noempty = true
          }
        })
      } else {
        let data = {
          entTApplicationUuid: applicationId,
          channelUuid: id,
          pageNum: _this.pageNum,
          pageSize: _this.pageSize
        }
        articles(data).then(res => {
          const { code, data } = res
          if (code === 1 && data) {
            _this.listData = data.rows
            _this.total = data.totalRows
          } else {
            this.noempty = true
          }
        })
      }
    },
    currentChange(e) {
      // console.log('e :>> ', e);
      var _this = this
      _this.pageNum = e
      _this.articles()
    }
  }
}
</script>
<style>
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'iconfont'; /* Project id 3165120 */
  src: url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.woff2?t=1661313604254') format('woff2'),
    url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.woff?t=1661313604254') format('woff'),
    url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.ttf?t=1661313604254') format('truetype');
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.1px;
  -moz-osx-font-smoothing: grayscale;
}
.enbusiness .image {
  width: 100%;
  height: 417px;
  overflow: hidden;
  min-width: 1200px;
  max-height: 330px;
  display: flex;
  margin: 0 auto;
  border: none;
  position: relative;
  z-index: 99;
}
.layout {
  width: 1200px;
  margin: 0 auto;
}
.worldwide {
  text-align: center;
}
.worldwide p {
  color: #333;
  font-family: Microsoft YaHei;
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
}
.worldwide div {
  height: 3px;
  background-color: #0162b3;
  content: '';
  display: block;
  margin-left: 50%;
  transform: translateX(-50%);
  width: 8%;
}
.worldwide img {
  width: 100%;
  height: 100%;
}
.business {
  background-color: #f9f9f9;
  padding: 20px 0;
}
.businesswrap {
  width: 1200px;
  margin: 0 auto;
}
.business ul {
  display: flex;
  justify-content: space-between;
}
.business ul li {
  cursor: pointer;
  text-align: center;
}
.business ul li img {
  margin: 20px 0;
  width: 40px;
}
.business ul li p {
  color: #333;
  font-family: Microsoft YaHei;
  font-size: 16px;
  font-weight: 400;
}
.business ul li div {
  background-color: #0162b3;
  content: '';
  display: block;
  height: 3px;
  width: 90%;
  margin-left: 5%;
}
.businessActive {
  color: #0162b3 !important;
}
.businessitem {
  margin-top: 20px;
  width: 100%;
}
.businessitem a {
  display: block;
  cursor: pointer;
  display: flex;
  height: 350px;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}
.businessitemL {
  box-sizing: border-box;
  height: 100%;
  padding: 50px 10px;
  width: 50%;
}
.businessitemL p {
  color: #333;
  font-family: Microsoft YaHei;
  font-size: 16px;
  font-weight: 400;
  line-height: 50px;
}
.businessitemL div {
  color: #333;
  font-family: Microsoft YaHei;
  font-size: 14px;
  font-weight: 400;
}
.businessitemL span {
  border: 1px solid #999;
  display: block;
  height: 36px;
  line-height: 36px;
  margin-top: 20px;
  text-align: center;
  width: 100px;
}
.businessitemR {
  width: 50%;
  height: 100%;
}
.businessitemR img {
  height: 100%;
  width: 100%;
}
.enbusiness .listContent {
  overflow: hidden;
  width: 1050px;
  height: 105px;
  float: right;
  margin-top: 34px;
  background-color: #e8e9ee;
  padding: 10px;
  box-sizing: border-box;
}
</style>
